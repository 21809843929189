import { ParentProps } from "solid-js";

import { styled } from "#style/jsx";

export type AppPageContainerProps = ParentProps<{}>;

export const AppPageContainer = (props: AppPageContainerProps) => {
  return (
    <styled.div
      backgroundColor="black"
      minH="full"
      w="full"
      px="10"
      color="white"
    >
      <styled.div w="full" pb="10">
        {props.children}
      </styled.div>
    </styled.div>
  );
};
